/* eslint-disable @next/next/no-img-element */
import React, {
    type FC,
    Fragment,
    type PropsWithChildren,
    useContext,
    useRef
} from 'react';
import classnames from 'classnames';
import dynamic from 'next/dynamic';
import { useIsLoggedIn } from 'bb/account/hooks/useVerifyLoggedIn';
import { AppContext } from 'bb/app/AppContext';
import { Link } from 'bb/app/nav/Link';
import css from 'bb/app/nav/Navbar/navbar.module.scss';
import { useRouter } from 'bb/app/router';
import { type RouterQuery } from 'bb/app/router/types';
import { useMarket } from 'bb/app/useMarket';
import { AppDownloadBadges } from 'bb/common/AppDownloadBadges';
import { MaxWidthContainer } from 'bb/common/Containers';
import { ExternalLink } from 'bb/common/ExternalLink';
import { KindlyChatWidget } from 'bb/common/KindlyChatWidget';
import { LoggingErrorBoundary } from 'bb/common/LoggingErrorBoundary';
import Logo from 'bb/common/Logo';
import { NumberOfBooks } from 'bb/common/NumberOfBooks';
import { PagePaddingBox } from 'bb/common/PagePaddingBox';
import { useFeature } from 'bb/config/features';
import { getMarketConfig } from 'bb/config/marketConfig';
import { Text, type TranslationPrefix, useTranslation } from 'bb/i18n';
import { CCImages } from 'bb/payment';
import {
    usePaymentProviderImages,
    type UsePaymentProviderImagesProps
} from 'bb/payment/hooks/usePaymentProviderImages';
import commonCss from 'bb/style/common.module.scss';
import { Box, Flex, List, ListItem, Row, Stack } from 'bb/ui';
import { type ListProps } from 'bb/ui/List/List';
import cssFooter from './footer.module.scss';
import { FooterListItem } from './FooterListItem';
import { Impressum } from './Impressum';

export type FooterListProps = {
    headingTextKey: TranslationPrefix;
    listProps?: Partial<ListProps>;
} & PropsWithChildren;

export const FooterList = ({
    headingTextKey,
    children,
    listProps = {}
}: FooterListProps) => {
    return (
        <div className={cssFooter.navList}>
            <Text
                variant="body1"
                as="h4"
                t={headingTextKey}
                color="primary-white"
            />
            <List {...listProps}>{children}</List>
        </div>
    );
};

const MarketPickerButton = dynamic(
    () =>
        import('../MarketPicker/MarketPickerButton').then(
            (m) => m.MarketPickerButton
        ),
    { ssr: true }
);

const CookieSettingsButton = dynamic(
    () => import('./CookieSettingsButton').then((m) => m.CookieSettingsButton),
    { ssr: false }
);

const PAYMENT_PROVIDER_IMAGES_PROPS: UsePaymentProviderImagesProps = {
    paypal: {
        className: cssFooter.paypal
    },
    klarna_paynow: {
        className: cssFooter.klarna
    },
    applepay: {
        className: cssFooter.apple
    },
    googlepay: {
        className: cssFooter.google
    },
    ideal: {
        className: cssFooter.ideal
    }
};

// eslint-disable-next-line complexity
export const Footer: FC = () => {
    const { t } = useTranslation([
        'nav',
        'common',
        'footer',
        'accountActivated',
        'countries'
    ]);
    const footerRef = useRef<HTMLAnchorElement>(null);
    const { routes, router } = useRouter();
    const { market } = useContext(AppContext);
    const enabledCancelSubscription = useFeature('nextweb-cancel-subscription');
    const { marketSettings } = useMarket();
    const isLoggedIn = useIsLoggedIn();
    const {
        giftCard: showGiftCard,
        footer: footerItems,
        showPartners
    } = getMarketConfig(market) || {};

    const { paymentMethods: paymentProviderImages } = usePaymentProviderImages(
        PAYMENT_PROVIDER_IMAGES_PROPS
    );

    const mappedPaymentProviderImages = paymentProviderImages.map(
        ([type, elements]) => {
            if (type === 'scheme') {
                return [
                    type,
                    <CCImages key={type}>{elements}</CCImages>
                ] as const;
            }

            return [type, elements] as const;
        }
    );

    const additionalFooterItems = footerItems || [];
    const weblinks = marketSettings?.weblinks;

    const showImpressum = additionalFooterItems?.includes('impressum');

    return (
        <footer className={cssFooter.base} ref={footerRef}>
            <nav className={css.black} aria-label={t('nav:footerNavigation')}>
                <PagePaddingBox pagePadding padding="large">
                    <LoggingErrorBoundary errorName="KindlyError">
                        <KindlyChatWidget
                            feature="nextweb-kindly-chatbot-account"
                            intersectionRef={footerRef}
                        />
                    </LoggingErrorBoundary>
                    <MaxWidthContainer maxWidth={992}>
                        <Stack
                            justifyContent="spaceBetween"
                            alignItems="stretch"
                            className={cssFooter.row}
                        >
                            <Stack
                                alignItems="flexStart"
                                className={cssFooter.bookbeat}
                            >
                                <Link
                                    route={
                                        isLoggedIn
                                            ? routes.welcome
                                            : routes.start
                                    }
                                    title={t('common:startPageLinkLabel')}
                                >
                                    <span>
                                        <Logo
                                            className={classnames(
                                                css.logo,
                                                css.whiteLogo
                                            )}
                                            variant="white"
                                        />
                                    </span>
                                </Link>

                                <Text t="footer:pitch" color="primary-white">
                                    <NumberOfBooks />
                                    <Link route={routes.start} />
                                </Text>
                            </Stack>

                            <Row
                                flexWrap="wrap"
                                className={cssFooter.navigationRow}
                            >
                                <FooterList headingTextKey="footer:aboutHeader">
                                    <FooterListItem>
                                        <Link route={routes.about}>
                                            <Text as="span" t="footer:about" />
                                        </Link>
                                    </FooterListItem>
                                    {weblinks?.presslink && (
                                        <FooterListItem show>
                                            {market === 'pl' ? (
                                                <ExternalLink
                                                    href={weblinks.presslink}
                                                    label={t('footer:press')}
                                                >
                                                    {t('footer:press')}
                                                </ExternalLink>
                                            ) : (
                                                <Link
                                                    query={
                                                        // Only do below on the affected page
                                                        // for cleaner url.
                                                        router.pathname ===
                                                        routes.press.href
                                                            ? ({
                                                                  // We need a dynamic query that
                                                                  // we can use for reloading the
                                                                  // iframe in /press if we click
                                                                  // press again we are on that page.
                                                                  reload: Date.now()
                                                              } as unknown as RouterQuery)
                                                            : undefined
                                                    }
                                                    route={routes.press}
                                                >
                                                    <Text
                                                        as="span"
                                                        t="footer:press"
                                                    />
                                                </Link>
                                            )}
                                        </FooterListItem>
                                    )}
                                    {weblinks?.jobs && (
                                        <FooterListItem show>
                                            <ExternalLink
                                                href={weblinks.jobs}
                                                label={t('footer:work')}
                                            >
                                                {t('footer:work')}
                                            </ExternalLink>
                                        </FooterListItem>
                                    )}
                                    <FooterListItem>
                                        <Link
                                            route={routes.environmentalImpact}
                                        >
                                            <Text
                                                as="span"
                                                t="footer:environmentalImpact"
                                            />
                                        </Link>
                                    </FooterListItem>
                                </FooterList>
                                <FooterList headingTextKey="footer:followHeader">
                                    {weblinks?.instagram && (
                                        <FooterListItem>
                                            <ExternalLink
                                                href={weblinks.instagram}
                                                label={t('footer:instagram')}
                                            >
                                                {t('footer:instagram')}
                                            </ExternalLink>
                                        </FooterListItem>
                                    )}
                                    {weblinks?.facebook && (
                                        <FooterListItem>
                                            <ExternalLink
                                                href={weblinks.facebook}
                                                label={t('footer:facebook')}
                                            >
                                                {t('footer:facebook')}
                                            </ExternalLink>
                                        </FooterListItem>
                                    )}
                                    {weblinks?.tiktok && (
                                        <FooterListItem>
                                            <ExternalLink
                                                href={weblinks.tiktok}
                                                label={t('footer:tiktok')}
                                            >
                                                {t('footer:tiktok')}
                                            </ExternalLink>
                                        </FooterListItem>
                                    )}
                                    {weblinks?.linkedin && (
                                        <FooterListItem>
                                            <ExternalLink
                                                href={weblinks.linkedin}
                                                label={t('footer:linkedIn')}
                                            >
                                                {t('footer:linkedIn')}
                                            </ExternalLink>
                                        </FooterListItem>
                                    )}
                                </FooterList>

                                <FooterList headingTextKey="footer:contactHeader">
                                    {weblinks?.support && (
                                        <FooterListItem>
                                            <ExternalLink
                                                href={weblinks.support}
                                                label={t('footer:faq')}
                                            >
                                                {t('footer:faq')}
                                            </ExternalLink>
                                        </FooterListItem>
                                    )}
                                    {showImpressum ? (
                                        <Impressum />
                                    ) : (
                                        <FooterListItem>
                                            <Link
                                                route={{
                                                    href: routes.contact.href,
                                                    name: t('footer:contact')
                                                }}
                                            >
                                                {t('footer:contact')}
                                            </Link>
                                        </FooterListItem>
                                    )}
                                    <FooterListItem
                                        show={additionalFooterItems?.includes(
                                            'school'
                                        )}
                                    >
                                        <Link
                                            route={{
                                                href: routes.school.href,
                                                name: t('footer:school')
                                            }}
                                        >
                                            {t('footer:school')}
                                        </Link>
                                    </FooterListItem>
                                </FooterList>
                                <FooterList headingTextKey="footer:informationHeader">
                                    {weblinks?.termsandconditions && (
                                        <FooterListItem>
                                            <ExternalLink
                                                href={
                                                    weblinks?.termsandconditions
                                                }
                                                label={t('footer:terms')}
                                            >
                                                {t('footer:terms')}
                                            </ExternalLink>
                                        </FooterListItem>
                                    )}
                                    {weblinks?.privacypolicy && (
                                        <FooterListItem>
                                            <ExternalLink
                                                href={weblinks.privacypolicy}
                                                label={t(
                                                    'footer:privacyNotice'
                                                )}
                                            >
                                                {t('footer:privacyNotice')}
                                            </ExternalLink>
                                        </FooterListItem>
                                    )}
                                    <FooterListItem>
                                        <Link
                                            route={{
                                                href: routes.cookies.href,
                                                name: t('footer:cookies')
                                            }}
                                        >
                                            {t('footer:cookies')}
                                        </Link>
                                    </FooterListItem>
                                    <FooterListItem>
                                        <CookieSettingsButton />
                                    </FooterListItem>
                                    <FooterListItem>
                                        <Link
                                            route={{
                                                href: routes.sitemap.href,
                                                name: t('footer:sitemap')
                                            }}
                                        >
                                            {t('footer:sitemap')}
                                        </Link>
                                    </FooterListItem>

                                    <FooterListItem
                                        show={Boolean(showPartners)}
                                    >
                                        <Link
                                            route={{
                                                href: routes.partners.href,
                                                name: t('footer:partners')
                                            }}
                                        >
                                            {t('footer:partners')}
                                        </Link>
                                    </FooterListItem>

                                    <FooterListItem
                                        show={enabledCancelSubscription}
                                    >
                                        <Link
                                            route={{
                                                href: routes.cancelSubscription
                                                    .href,
                                                name: t(
                                                    'footer:cancelSubscription'
                                                )
                                            }}
                                        >
                                            {t('footer:cancelSubscription')}
                                        </Link>
                                    </FooterListItem>
                                </FooterList>

                                <FooterList headingTextKey="footer:bookBeat">
                                    <FooterListItem>
                                        <Link route={routes.registration}>
                                            {t('footer:tryBookBeat')}
                                        </Link>
                                    </FooterListItem>
                                    <FooterListItem>
                                        <Link
                                            route={{
                                                href: routes.books.href,
                                                name: t('nav:books')
                                            }}
                                        >
                                            {t('nav:books')}
                                        </Link>
                                    </FooterListItem>
                                    <FooterListItem>
                                        <Link
                                            route={{
                                                href: routes['e-books'].href,
                                                name: t('footer:eBooksFooter')
                                            }}
                                        >
                                            {t('footer:eBooksFooter')}
                                        </Link>
                                    </FooterListItem>
                                    <FooterListItem>
                                        <Link
                                            route={{
                                                href: routes.categories.href,
                                                name: t('footer:categories')
                                            }}
                                        >
                                            {t('footer:categories')}
                                        </Link>
                                    </FooterListItem>
                                    <FooterListItem>
                                        <Link
                                            route={{
                                                href: routes.search.href,
                                                name: t('footer:search')
                                            }}
                                        >
                                            {t('footer:search')}
                                        </Link>
                                    </FooterListItem>
                                    {showGiftCard && (
                                        <FooterListItem>
                                            <Link
                                                route={{
                                                    href: routes.buyGiftcards
                                                        .href,
                                                    name: t('footer:giftCard')
                                                }}
                                            >
                                                {t('footer:giftCard')}
                                            </Link>
                                        </FooterListItem>
                                    )}
                                    {weblinks?.familyaccount && (
                                        <FooterListItem>
                                            <Link
                                                route={{
                                                    href: routes.familyaccount
                                                        .href,
                                                    name: t(
                                                        'footer:familyAccount'
                                                    )
                                                }}
                                            >
                                                {t('footer:familyAccount')}
                                            </Link>
                                        </FooterListItem>
                                    )}
                                </FooterList>

                                <FooterList
                                    listProps={{ alignItems: 'flexStart' }}
                                    headingTextKey="footer:download"
                                >
                                    <ListItem>
                                        <AppDownloadBadges
                                            appStoreLink={t(
                                                'common:appStoreLink'
                                            )}
                                            color="white"
                                            className={commonCss.noPadding}
                                        />
                                        <AppDownloadBadges
                                            playStoreLink={t(
                                                'common:playStoreLink'
                                            )}
                                            color="white"
                                            className={commonCss.noPadding}
                                        />
                                    </ListItem>
                                </FooterList>
                            </Row>
                        </Stack>
                    </MaxWidthContainer>
                    <MaxWidthContainer maxWidth={992}>
                        <Box marginTop="medium">
                            <Flex wrap="wrapReverse" alignItems="center">
                                <Box className={cssFooter.copyrightColumn}>
                                    <Text color="primary-white">
                                        &copy;BookBeat AB,{' '}
                                        {new Date().getFullYear()}
                                    </Text>
                                </Box>
                                <Box className={cssFooter.columnContainer}>
                                    <Flex
                                        alignItems="center"
                                        className={cssFooter.marketColumn}
                                        gap="small"
                                    >
                                        <MarketPickerButton />
                                    </Flex>

                                    <Flex
                                        alignItems="center"
                                        className={cssFooter.paymentColumn}
                                    >
                                        {mappedPaymentProviderImages.map(
                                            ([type, elements]) => (
                                                <Fragment key={type}>
                                                    {elements}
                                                </Fragment>
                                            )
                                        )}
                                    </Flex>
                                </Box>
                            </Flex>
                        </Box>
                    </MaxWidthContainer>
                </PagePaddingBox>
            </nav>
        </footer>
    );
};
